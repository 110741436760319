import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Helmet } from 'react-helmet';

import ContentWrapper from '../components/contentWrapper';
import styles from '../styles/pagesComponents/legalNoticePage.module.scss';

const LegalNoticePage = ({ intl }) => (
  <ContentWrapper>
    <Helmet>
      <title>{intl.formatMessage({ id: 'menu.legal' })}</title>
      <meta property="og:title" content={intl.formatMessage({ id: 'menu.legal' })} />
    </Helmet>
    <div className={styles.container}>
      <h2 className={styles.header}>
        <FormattedMessage
          id="menu.legal"
        />
      </h2>
      <div className={styles.content}>
        <h3>
          <FormattedMessage
            id="legal.address.label"
          />
        </h3>
        <FormattedMessage
          id="legal.address.line1"
        />
        <FormattedMessage
          id="legal.address.line2"
        />
        <FormattedMessage
          id="legal.address.line3"
        />
        <FormattedMessage
          id="legal.address.line4"
        />
        <span>
          <FormattedMessage
            id="legal.contact.email"
          />
          <a href="mailto:admin@msfint.com">admin@msfint.com</a>
        </span>
        <FormattedMessage
          id="legal.contact.phone"
        />
        <span>
          <FormattedMessage
            id="legal.contact.website"
          />
          <a
            href="https://www.msfint.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.msfint.com
          </a>
        </span>
        <h3>
          <FormattedMessage
            id="legal.representative.label"
          />
        </h3>
        <FormattedMessage
          id="legal.representative.name"
        />
        <FormattedMessage
          id="legal.representative.with"
        />
        <FormattedMessage
          id="legal.representative.number"
        />
        <FormattedMessage
          id="legal.representative.code"
        />
        <h3>
          <FormattedMessage
            id="legal.disclaimer.label"
          />
        </h3>
        <FormattedMessage
          id="legal.disclaimer.text"
        />
      </div>
    </div>
  </ContentWrapper>
);

LegalNoticePage.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(LegalNoticePage);
